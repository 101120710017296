import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../AppStore';
import { ProductApi } from '../../api/ProductApi';
import { myConfigurationData, productCatalogData } from '../Helpers/ProductSettingsSliceHelper';

const initialState = {
  productCatalog: {},
  productCatalogDetails: {page:1,limit:15,totalRecords:1,agCodes:[], channelCodes: []},
  myConfigurations: {},
  myConfigPageDetails: {page:1,limit:15,totalRecords:1, applicationIds: [], channelCodes:[]},
  productHierarchy: [],
  channelHierarchy: [],
  isSearhContent: false
}

//Gets the product model details
export const getProductCatalog = createAsyncThunk( 'productSettings/getProductCatalog', async ( action:{ token: string, page:number, limit:number, productSearch?: string, agCodes?: string[], channelCodes?: string[] } )=>{
  return ProductApi.productCatalog( action );
} );

//Gets product hierarchy
export const getProductHierarchy = createAsyncThunk( 'productSettings/getProductHierarchy', async ( action:{ token: string } )=>{
  return ProductApi.productHierarchy( action );
} );

//Gets the channels that are available
export const getChannelHierarchy = createAsyncThunk( 'productSettings/getChannelHierarchy', async ( action:{ token: string } )=>{
  return ProductApi.channelHierarchy( action );
} );

//Gets all the user specific configurations
export const getMyConfigurations = createAsyncThunk( 'productSettings/getMyConfigurations', async ( action:{ token: string, page:number, limit:number, productSearch?: string, applicationIds?: string[], channelCodes?: string[] } )=>{
  return ProductApi.myConfigurations( action );
} );


const productSettingsSlice = createSlice( {
  name:'productSettings',
  initialState:initialState,
  reducers:{
    updateProductCatalogDetails( state, action ) {
      state.productCatalogDetails = {page:action.payload.page, limit:action.payload.limit,totalRecords:action.payload.totalRecords,agCodes:action.payload.agCodes, channelCodes: action.payload.channelCodes};
    },
    updateMyConfigPageDetails( state, action ) {
      state.myConfigPageDetails = {page:action.payload.page, limit:action.payload.limit,totalRecords:action.payload.totalRecords,applicationIds: action.payload.applicationIds, channelCodes:action.payload.channelCodes};
    },
    resetMyConfigurations( state ) {
      state.myConfigurations = [];
      state.channelHierarchy = [];
      state.myConfigPageDetails = {page:1,limit:15,totalRecords:1,applicationIds: [],channelCodes:[]};
    },
    checkIsSearchContent( state, action ) {
      state.isSearhContent = action.payload.flag;
    },
  },
  extraReducers: ( builder ) =>{
    builder.addCase( getProductCatalog.fulfilled, ( state, action ) => {
      if( action.payload ) {
        const data = productCatalogData( action.payload,state )
        state.productCatalog = data.productCatalog;
        state.productCatalogDetails = data.productCatalogDetails;
      }
    } );

    builder.addCase( getProductHierarchy.fulfilled, ( state, action ) => {
      if( action.payload ) {
        state.productHierarchy = action.payload.productHierarchy ? action.payload?.productHierarchy?.reverse() : []
      }
    } );

    builder.addCase( getChannelHierarchy.fulfilled, ( state, action ) => {
      if( action.payload ) {        
        state.channelHierarchy = action.payload.channelHierarchy ? action.payload?.channelHierarchy?.reverse() : [] 
      }
    } );

    builder.addCase( getMyConfigurations.fulfilled, ( state, action ) => {
      if( action.payload ) {
        const myConfiguration = myConfigurationData( action.payload, state );
        state.myConfigurations = myConfiguration.myConfigurations;
        state.myConfigPageDetails = myConfiguration.myConfigPageDetails;
      }
    } );

  }
} )

export const { updateProductCatalogDetails, updateMyConfigPageDetails, resetMyConfigurations, checkIsSearchContent } = productSettingsSlice.actions

export const productSettings = ( state: AppState ) => state

export default productSettingsSlice.reducer
