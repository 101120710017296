import { EActionType, UserAssignmentsScope, IdFormat, PriorityValue, EConfigurationProperty, ESessionStore, EUrlParams, EVariableType } from '../../data/Constants';
import { createVariableAssignments, getConflict, addPdmProperties, isValidConfigurableSection, isSubmodelCountVariable, UrlHelper } from '../../services';
import { SessionStore } from '../../services/SessionStore';
import { appSettings } from '../../settings';
import { ILoadConfiguration, IConfigurationAction, IExtendedConfigureResponseOptional, IExtendedConfigureResponse, IContextDataCountMap, IConfiguration, IContextData, ISection, IOrderingInstructionsPMP, IConflictHierarchy, IAssignment, IContextDataModel, IVariableAssignment, IMaterialImages } from '../../../types';

const createOptionalItems = ( data: ILoadConfiguration ) => {
  const optionalItems = new Map<string, boolean>();
  if ( data.optionalItems ) {
    for ( const key of Array.from( data.optionalItems?.keys() ) ) {
      optionalItems.set( data.optionalItems[key], true );
    }
  }
  data.optionalItems = optionalItems;
}

export const loadedConfiguration = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional )=>{
  if ( action.data ) {
    createOptionalItems( action.data )
  }
  const variableAssignments = createVariableAssignments( action.data.userAssignments )
  const newState = { ...state, savedConfiguration: action.data, contextData: getContextData( action.data ), assignments: variableAssignments,access:action.access };
  const OrderingInstructions = {};
  createORIValuesforPMP( action.configuration ? action.configuration : action.data.configureResponse,OrderingInstructions )
  const data = configured( action, newState )
  return {
    savedConfiguration: action.data, 
    contextData: data?.contextData, 
    assignments: variableAssignments, 
    access:action.access,
    orderingInstructions:OrderingInstructions,
    conflict: data?.conflict,
    data: data?.data,
    assignments: data?.assignments,
    isInvalid: data && ( data.isInvalid === false || data.isInvalid ) ? data.isInvalid : null,
    acceptedChanges: data?.acceptedChanges
  }
}

export const configured = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional ) => {
  action.configuration = action.configuration ? action.configuration : action.data.configureResponse;
  if ( !action.configuration ) {
    return null;
  }
  const assignmentsToRemove = action.configuration.removedAssignments?.variableAssignments;
  let assignments = state.assignments ? [...state.assignments] : [];
  if( action.apiType === EActionType.ClearAll ) {
    assignments = assignments.filter( assignedmentVal => UserAssignmentsScope.find( userVal => userVal === assignedmentVal.variableId ) ) //Update UserAssignmentsScope in Constant file if new static id adds up
  }
  const conflict = state.isInvalid !== false ? null : getConflict( { ...state, data: { ...action.configuration } } as IExtendedConfigureResponse );
  const shortSalesText = state.shortSalesText;
  
  if ( assignmentsToRemove ) {
    assignments = removeAssignments( assignmentsToRemove, shortSalesText, assignments );
  }
  const args = action.configuration.arguments?.Configuration || {};
  const submodels = Object.keys( args ).filter( e => {
    return e.endsWith( IdFormat.Suffix.SubmodelId ) && args[e] >= 1
  } );
  submodels.forEach( m => {
    const ass = assignments.find( a => a.variableId === m );
    if ( !ass ) {
      assignments.push(
        { assignmentType: 'Singleton', variableId: m, value: args[m].toString(), exclude: false, priority: PriorityValue.High }
      )
    } else if ( Number( ass.value ) >= 1 ) {
      ass['priority'] = PriorityValue.High;
    }
  } )
  sanitizeConfiguration( action.configuration, state.contextData );
  const countVariables: IContextDataCountMap = {};
  state.contextData?.countVariables && Object.keys( state.contextData?.countVariables ).forEach( ( k ) => {
    countVariables[k] = null;
  } )
  const bundledFeatures: { [key: string]: boolean } = {};
  mapCountAndBundleFeatures( action.configuration.sections, countVariables, bundledFeatures );
  
  //Update the variable and value  configit properties using  content folder propert Associations response
  action.configuration.sections = addPdmProperties( action.configuration.sections,state.propertyAssociations );
  //remove once real properties are available
  // fixSubModelViews( action.configuration );
  return checkInvalid( { ...state, contextData: { ...state.contextData, countVariables: { ...countVariables } }, bundledFeatures: bundledFeatures }, action, conflict, assignments, assignmentsToRemove )
}


const sanitizeConfiguration = ( configuration: IConfiguration, contextData: IContextData | null ) => {
  configuration.sections = configuration.sections?.filter( ( s ) => sanitizeSection( contextData, s ) );
}

// function to create orderingInstructions data for PM & SubModel
const createORIValuesforPMP = ( configuration: IConfiguration,OrderingInstructions ) => {  
  configuration.sections?.forEach( ( s ) => checkORIOnHiddenSec( s,OrderingInstructions,configuration.product ) );
}


// check ORI property on Hidden Section
const checkORIOnHiddenSec = ( section: ISection,OrderingInstructions:IOrderingInstructionsPMP,Product:{id:string} ) => {
  if( section.sections.length > 0 ) {
    section.sections?.forEach( ( s ) => {
      checkORIOnHiddenSec( s,OrderingInstructions,Product ) 
    } )
  }
  if( !section.id || section.id?.endsWith( '_HIDDEN_' ) ) {
    const pmpVariable = section.variables.find( variable => variable.id.includes( 'PMP' ) );
    const pmpVariableORI = pmpVariable && pmpVariable.properties.find( property => property.id === EConfigurationProperty.OrderingInstructions )
    if( pmpVariableORI ) { 
      // adding ORI data for SubModels     
      if( pmpVariable.id.split( '.' ).length > 1 ) {
        const pmpVariableKey = pmpVariable.id.split( '.' );
        let pmpVariableId = pmpVariableKey[pmpVariableKey.length - 2];
        pmpVariableId = pmpVariableId.substring( pmpVariableId.indexOf( '_' ) + 1, pmpVariableId.length )
        OrderingInstructions[pmpVariableId] = {value:pmpVariableORI.value}
      }else {
        // adding ORI data for main PM
        OrderingInstructions[Product.id] = {value:pmpVariableORI.value}
      }
    }
  }
    
}
  
const sanitizeSection = ( contextData: IContextData | null, section: ISection ) => {
  section.sections = section.sections?.filter( ( s ) => sanitizeSection( contextData, s ) );
  return isValidConfigurableSection( contextData, section );
}
  
const checkInvalid = ( state: IExtendedConfigureResponseOptional, action: IConfigurationAction, conflict: { assignmentsToRemove: IConflictHierarchy; assignmentsBefore: IAssignment[]; } | null, assignments: IAssignment[], assignmentsToRemove: string | any[] | undefined ) => {
  if ( state.isInvalid === null ) {
    if ( assignmentsToRemove?.length ) {
      return { conflict, data: action.configuration, assignments, isInvalid: true, acceptedChanges: false, contextData: state.contextData };
    } else {
      return { conflict, data: action.configuration, assignments, isInvalid: false, acceptedChanges: false , contextData: state.contextData};
    }
  } else {
    if ( state.isInvalid ) {
      //If user accepts proposed changes
      return { conflict: null, data: action.configuration, assignments, isInvalid: false, acceptedChanges: true, contextData: state.contextData }
    }
    return { conflict, data: action.configuration, assignments, isInvalid: state.isInvalid, contextData: state.contextData };
  }
}
  
/**
   * Generates and returns the context data from model context
   * @param {ILoadConfiguration} data The data received from read api call
   * @returns {IContextData} context data which maps model id to model context
   */
function getContextData( data?: ILoadConfiguration ) {
  if ( !data ) {
    return { models: null }
  }
  const modelContext = data.modelContext;
  const { rootModel, subModels } = modelContext
  const models: { [key: string]: IContextDataModel } = { [rootModel.id]: { ...rootModel, isRoot: true } };
  if ( rootModel.id ) {
    SessionStore.set( ESessionStore.ProductId, rootModel.id );
  }
  const countVariables: IContextDataCountMap = {};
  subModels.forEach( ( sm ) => {
    models[sm.id] = sm
    if ( sm.subModelVariableIds?.length ) {
      sm.subModelVariableIds?.forEach( ( v ) => {
        countVariables[v] = null;
      } )
    }
  } );
  
  return { models, countVariables }
}
  
/**
   * Returns an object that maps the count variables from configure response to the count variables ids in context data
   * @param {ISection[]} sections the sections received in configure response
   * @param {IContextDataCountMap} countVariables the object to store the map
   * @param {bundledFeatures} bundledFeatures the object to store the map of bundle features
   * @returns {void}
   */
function mapCountAndBundleFeatures( sections: ISection[], countVariables: IContextDataCountMap, bundledFeatures: { [key: string]: boolean } ) {
  sections.forEach( s => {
    s.variables.forEach( v => {
      v.values.filter( val => val.state.isAssigned )?.forEach( val => {
        const vl = val.properties?.find( vp => vp.id === EConfigurationProperty.BundleContent );
        vl?.value?.toString().split( ',' ).forEach( vid => bundledFeatures[vid] = true );
      }
      )
      if ( v.id in countVariables || isSubmodelCountVariable( v ) ) {
        countVariables[v.id] = v;
      }
    } )
    s.sections?.length && mapCountAndBundleFeatures( s.sections, countVariables, bundledFeatures );
  } )
}
  
// To get shortSalesText data
function getShortSalesTextData( state: IExtendedConfigureResponseOptional, action: IConfigurationAction ) {
  const shortSalesText = state.shortSalesText || {};
  const shortSalesTextData: { [key: string]: { id: string, text: string } } = {
  }
  action?.salesText && action.salesText.salesTexts?.forEach( ( text: any ) => {
    if ( text.id && !( text.id in shortSalesText ) ) {
      shortSalesTextData[text.id] = text;
    }
  } )
  const productModelId = 'Model_' + action.modelId;
  if ( action.modelId ) {
    shortSalesTextData[productModelId] = { id: action.modelId, text: '' }
  }
  return { ...shortSalesText, ...shortSalesTextData }
}
  
export const setSalesText = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional ) => {
  if ( action.apiType === 'short' ) {
    return getShortSalesTextData( state, action );
  } else {
    const longSalesText = state.longSalesText || [];
    const data = action.salesText?.salesTexts || [];
    const modelPath = UrlHelper.getSearchParameter( EUrlParams.Model );  
    const subModelCollection = modelPath ? modelPath.split( '.' ) : [] ;
    //concat SalesText of existing data from state & subModel data from api
    if( subModelCollection.length > 0 ) {
      return [...longSalesText, ...data]
    } else {
      return [ ...data] // override salesText data on lang change if not submodel
    }    
  }
}
  
export const getPriceData = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional ) => {
  const price = state.price || {};
  const priceData: { [key: string]: { materialCode: string, price: number } } = {
  }
  action?.price && action.price.prices.forEach( ( text: any ) => {
    if ( text.materialCode && !( text.materialCode in price ) ) {
      priceData[text.materialCode] = text;
    }
  } )
  return { ...price, ...priceData }
}
  
const removeAssignments = ( assignmentsToRemove: IVariableAssignment[], shortSalesText: { [key: string]: any } | undefined, assignments: IAssignment[] ) => {
  const validAssignments = [...assignments];
  for ( const assignmentToRemove of assignmentsToRemove ) {
    assignmentToRemove.variable.shortSalesText = appSettings.UseShortSalesText && shortSalesText && shortSalesText[assignmentToRemove.variable.id] ?
      shortSalesText[assignmentToRemove.variable.id].text : null;
    assignmentToRemove.value.shortSalesText = appSettings.UseShortSalesText && shortSalesText && assignmentToRemove.value.value && shortSalesText[assignmentToRemove.value.value.toString()] ?
      shortSalesText[assignmentToRemove.value.value.toString()].text : null;
    let index = -1;
    //Compare the feature value name for Numeric features and rest all type features compare the value
    if( assignmentToRemove.variable.valueType === EVariableType.Number ) {
      index = validAssignments.findIndex( a => a.variableId === assignmentToRemove.variable.id && a.value === assignmentToRemove.value.name );
    }else{
      index = validAssignments.findIndex( a => a.variableId === assignmentToRemove.variable.id && a.value === assignmentToRemove.value.value );
    }
    if( index >= 0 ) {
      validAssignments.splice( index, 1 )
    }
  }
  return validAssignments;
}
  
export const setProductImages = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional ) => {
  action.productImages = action.productImages && typeof action.productImages === 'string' ? JSON.parse( action.productImages ) : action.productImages;
  const productImages = state.productImages || {};
  const updatedImageData: { [key: string]: { materialCode: string, materialImages: IMaterialImages[] } } = {
  }
  action?.productImages && action.productImages.productImages?.forEach( ( data: any ) => {
    if ( data.materialCode && !( data.materialCode in productImages ) ) {
      updatedImageData[data.materialCode] = data;
    }
  } )
  const productModelId = 'Model_' + action.modelId;
  if ( action.modelId ) {
    updatedImageData[productModelId] = { materialCode: action.modelId, materialImages: [] }
  }
  return { ...productImages, ...updatedImageData }
}