import { EVariableType, IdFormat, ScopeSectionVariableIds, ChangedNameVariableIds, EConfigurationProperty, EJustification } from '../data/Constants';
import { IConfigurationValue, IConfigurationVariable, IFeatureOrFamily, ISection, IUserSettings, ISectionPrice,IConfigurationVariablePrice, IConfigurationValuePrice,IExtendedConfigureResponse,IDetailedSummary } from '../../types';
import { checkIsMarkedOptional, getNameWithCode, getSectionInfo, getVariableType, getViewIdSuffix, isSubModelAssigned,getSubModelsFromSectionId } from './ConfigurationDataHelperFunctions';
import { t } from 'i18next';
const type = 'summaryPanel'; 

/**
 * Returns a comma separated string of assigned values for the variable
 * @param {IConfigurationVariable} variable the variable to check for assigned values 
 * @param {IUserSettings} userSettings to get showCode 
 * @returns {string} the comma separated string of assigned values
 */
export function getValueFromVariable( variable : IConfigurationVariablePrice, userSettings:IUserSettings,pageType?:string ) {
  if( !variable?.values ) {
    return [];
  }
  if( !pageType ) {
    pageType = type;
  }
  const showCode = userSettings?.showCode && ( variable.variableType !== EVariableType.String && variable.variableType !== EVariableType.Number && variable.variableType !== EVariableType.Date )
  // Filtering assigned values and setting price and name to return array of assigned values with price
  return variable.values.filter( isValidAssignedValue ).map( a => {
    // geting value name and price from the variable data
    const value = {name:getNameWithCode( a as IFeatureOrFamily,showCode ,pageType ),price:a.price,isBundled:a.isBundled,isMarkedOptional:a.isMarkedOptional,shortSalesText:a.shortSalesText}
    if( variable.variableType === EVariableType.String || variable.variableType === EVariableType.Number ) {
      value.price = variable.price;
    }
    return value
  } );
}

/**
 * Returns the name to be shown in the summary pane for the given variable
 * @param {IConfigurationVariable} variable the variable to check
 * @param {IUserSettings} userSettings to get showCode 
 * @returns {string} the name to be shown in the summary pane
 */
export const getSummaryVariableName = ( variable:IConfigurationVariable ,userSettings:IUserSettings ):string => {
  const id = getVariableIdWithoutPrefixes( variable );
  const showCode = userSettings?.showCode
  if( ChangedNameVariableIds.includes( id ) ) {
    return getNameWithCode( {...variable,name:t( 'labels.' + id )} as IFeatureOrFamily,showCode,type );
  }
  return getNameWithCode( variable as IFeatureOrFamily,showCode,type );
}

/**
 * Checks if any variable inside the hierarchy of the given section is assigned
 * @param {ISection} section the section to check
 * @returns {boolean} true if section contains assigned value
 */
export function isSectionSelected( section: ISection ): boolean {
  if ( !section ) {
    return false;
  }
  //removing scope for submodels
  if( isSubModel( section ) ) {
    section.sections = section.sections?.filter( ( sec:ISection ) => !isScope( sec ) );
  }
  //check if a variable has assigned values
  for ( const variable of section.variables ) {
    if ( isSubmodelCountVariable( variable ) ) {
      continue;
    }

    const assignedValues = variable.values.filter( isValidAssignedValue );
    if ( assignedValues.length > 0 ) {
      return true;
    }
  }

  //check if a subsection is selected
  if( section.sections.filter( ( s ) => isSectionSelected( s ) ).length > 0 ) {
    return true;
  }

  return false;
}

/**
 * Goes through all sections and returns a new list with selected sections only, unselected subsections will be not included.
 * @param {ISection[]} sections the sections to iterate
 * @returns {ISection[]} a new list with selected sections only
 */
export function getOnlySelectedSections( sections: ISection[] | undefined ) {
  
  if( !sections ) {
    return [];
  }

  const sectionsCopy = JSON.parse( JSON.stringify( sections ) );
  const result = [];
  for( const section of sectionsCopy ) {
    if( !isSubModel( section ) && isEmptySection( section ) ) {
      continue;
    }

    result.push( section );
  }

  return result;
}

/**
 * Recursive method to keep only subsections which have at least one assignment. 
 * @param {ISection} section the section to check
 * @returns {boolean} true if section is not selected.
 */
function isEmptySection( section: ISection ) {
  if( isSectionSelected( section ) ) {
    const result = [];

    for( const subSection of section.sections ) {
      if( isEmptySection( subSection ) ) {
        continue;
      }

      result.push( subSection );
    }

    section.sections = result;
    return false;
  }
  return true;
}

/**
 * Calculates the total price based on the variables inside the sections
 * @param {ISection[]} sections an array of all sections
 * @param {IExtendedConfigureResponse} configuration the configuration
 * @returns {number} the total price
 */
export function calculatePrice( rootSection:ISectionPrice,configuration:IExtendedConfigureResponse ) {
  let totalPrice = rootSection.price;
  rootSection.sections.filter( ( s:ISection ) => isSubModel( s ) && isSubModelAssigned( configuration,getSubModelsFromSectionId( s.id ) ) ).forEach( ( sec:ISectionPrice )=>{
    totalPrice = totalPrice + calculatePrice( sec,configuration );
  } )
  if( rootSection.basePrice ) {
    totalPrice = totalPrice + rootSection.basePrice;
  }
  return totalPrice;
}

/**
 * Checks if the variable should be priced
 * @param {IConfigurationVariable} variable the variable to check
 * @returns {boolean} true, if variable should be priced
 */
export const isVariablePriced = ( variable : IConfigurationVariable ) => 
  variable.id && 
  !ScopeSectionVariableIds.includes( getVariableIdWithoutPrefixes( variable ) );
/**
 * Checks if the section represents a submodel
 * @param {ISection} section the section to be checked
 * @returns {boolean} true if current section represents a submodel
 */
export const isSubModel:( section:ISection ) => boolean = ( section:ISection ) =>{
  const sectionParts = section.id.split( '.' );
  return sectionParts?.length === sectionParts.filter( ( sec:string )=> getSectionInfo( sec ).type === 'model' ).length && sectionParts.length > 1;
} 

/**
 * Checks if the section represents a scope section
 * @param {ISection} section the section to be checked
 * @returns {boolean} true if current section represents a scope section
 */
export const isScope:( section:ISection ) => boolean = ( section:ISection ) => getSectionInfo( section.id ).type === 'scope';

/**
 * Checks if the section represents has a valid section id
 * @param {ISection} section the section to be checked
 * @returns  {boolean} true if current section has a valid section id
 */
export const isSection:( section:ISection ) => boolean = ( section:ISection ) => getSectionInfo( section.id ).type === 'section'


/**
 * Returns the variable id without any prefixes
 * @param {IConfigurationVariable} variable the variable to check
 * @returns {string} the variable id without prefixes
 */
export const getVariableIdWithoutPrefixes = ( variable : IConfigurationVariable ) => 
  variable.id.split( '.' ).slice( -1 )[0] 

/**
 * Checks if the value is assigned, not zero and not a LESS feature
 * @param {IConfigurationValue} value the value to check
 * @returns {boolean} true if the value is assigned, not zero and not a LESS feature
 */
const isValidAssignedValue = ( value: IConfigurationValue ) =>   
  value.value && value.state.isAssigned && value.state.assigned !== EJustification.Phase &&
  value.value !== 0 &&
  !value.value.toString().endsWith( IdFormat.Suffix.LessFeature )
   
function getStringNumericPrice( variable:IConfigurationVariablePrice, value:string | number | boolean ) {
  if( variable.variableType === EVariableType.Number && variable.price === null ) {
    return null;
  }
  return variable.variableType === EVariableType.Number ? Number( value ) * variable.price : variable.price;
}
/**
 * Appends price to values and calculates variable's price
 * @param {IConfigurationVariablePrice} variable the variable to append price to
 * @returns {void}
 */
export const appendVariablePrice = ( variable:IConfigurationVariablePrice ) => {
  let price = 0;
  if( !variable?.values ) {
    return variable.price;
  }
  // Filtering assigned values and setting price and name to return array of assigned values with price
  variable.values.forEach( ( v ) => {
    const val = v;
    if( isValidAssignedValue( val ) ) {
      if( [EVariableType.Number,EVariableType.String].includes( variable.variableType as EVariableType.Number | EVariableType.String ) ) {
        variable.price = getStringNumericPrice( variable,val.value )
        price += variable.price;
        return price
      }else{
        price += val.price ? val.price : 0;
      }
    } else{
      val.price = 0;
     
    }
    return price
  } )
  return price;
}

/**
 * Appends and calculates the price for the section and its subsections
 * @param {ISectionPrice} section the section to append price to
 * @returns {void}
 */
export const appendSectionPrice = ( section:ISectionPrice )=>{
  section.price = section.price ? section.price : 0 ;
  section.sections.forEach( ( s:ISectionPrice )=>{
    if( isSubModel( s as ISection ) || isScope( s as ISection ) ) {
      appendSectionPrice( s );
    } else{
      section.price += appendSectionPrice( s ) ;
    }
  } );
  section.variables.forEach( ( v:IConfigurationVariablePrice )=>{
    section.price += appendVariablePrice( v ) ;
  } )
  return section.price;
}

/**
 * Generates the model path based on the id of given section
 * @param {ISection} section the section to get model path for
 * @returns {string} the model path for the given section
 */
export const getModelPathFromSection = ( section:ISection|IDetailedSummary ) => {
  const viewId = getViewIdSuffix();
  return section.id.split( '.' ).filter( ( part )=>part.endsWith( viewId ) )?.map( ( p )=>p.replace( viewId,'' ) ).slice( 1 )?.join( '.' ) || ''; 
}

/**
 * Returns the first assigned value for the variable
 * @param {IConfigurationVariable} variable the variable to get assigned value of
 * @returns {IConfigurationValue} the first assigned value of the variable
 */
export const getAssignedValue = ( variable?:IConfigurationVariable|null ) => {
  if( !variable ) {
    return null;
  }
  return variable.values.find( isValidAssignedValue );
}

/**
 * Checks if variable is submodel count variable or not
 * @param {IConfigurationVariable} variable the variable to check
 * @returns {boolean} whether variable is submodel count variable or not
 */
export const isSubmodelCountVariable = ( variable:IConfigurationVariable ) => !!( variable.properties.find( ( p )=>p.id === EConfigurationProperty.SubModelQty )?.value && variable.id.endsWith( IdFormat.Suffix.SubmodelId ) );

/**
 * checks if the isMarkedOptional attribute is t be added to the variable/value, subtracts optional items price and returns if the variable changed
 * @param {IConfigurationVariablePrice} variable the variable to check
 * @returns {boolean} whether the variable changed and is to be updated
 */
export const appendIsMarkedOptionalVariable = ( variable:IConfigurationVariablePrice )=>{
  let toBeAdded = false;
  const varType = getVariableType( variable );
  const optionalVariable:IConfigurationVariablePrice = {...variable,values:[] as IConfigurationValuePrice[],isMarkedOptional:false,price:0};
  variable.values.forEach( v=>{
    if( checkIsMarkedOptional( `${v.value}`,variable ) ) {
      toBeAdded = true;
      optionalVariable.values.push( v );
      optionalVariable.price += v.price; 
      v.isMarkedOptional = true;
    }
  } );
  if( varType === EVariableType.Date || varType === EVariableType.Number || varType === EVariableType.String ) {
    let isMarkedOptional = checkIsMarkedOptional( variable.id,variable );
    if( variable.variableType === EVariableType.Number && isMarkedOptional ) {
      const assigned = variable.values?.find( v=> v.state.isAssigned );
      isMarkedOptional = assigned && assigned.value as number <= 0 ? false : isMarkedOptional
    }
    optionalVariable.price = variable.price;
    optionalVariable.isMarkedOptional = isMarkedOptional;
    optionalVariable.values = variable.values;
    variable.isMarkedOptional = isMarkedOptional;
    toBeAdded ||= variable.isMarkedOptional || false;
  }
  if( toBeAdded ) {
    variable.price -= optionalVariable.price;
  }
  variable.values = variable.values.filter( ( v )=>!v.isMarkedOptional )
  return toBeAdded ? optionalVariable : null;
}

/**
 * Appends optional section to the models that have optional items present and updates the price
 * @param {ISectionPrice} section the section to check and aapend
 * @returns {void}
 */
export const appendOptional = ( section:ISectionPrice )=>{
  let toBeAdded = false;
  const optionalSection:ISectionPrice = {
    id:`${section.id}-optional`,
    sections:[] as ISectionPrice[],
    variables:[] as IConfigurationVariablePrice[],
    price:0
  } as ISectionPrice;
  section.variables.forEach( v=>{
    const optVar = appendIsMarkedOptionalVariable( v );
    if( optVar ) {
      toBeAdded = true;
      optionalSection.variables.push( optVar );
      optionalSection.price += optVar.price;
    }
  } )
  section.sections.forEach( s=>{
    if( isSubModel( s ) ) {
      appendOptional( s );
    } else{
      appendOptional( s );
      if( s.optional ) {
        toBeAdded = true;
        optionalSection.sections.push( {...s,sections:s.optional.sections,variables:s.optional.variables} );
        optionalSection.price += s.optional.price;
      }
    }
  } );
  section.variables = section.variables.filter( ( v )=>!v.isMarkedOptional && v.values.length && !v.id.includes( IdFormat.Suffix.Role ) );
  if( toBeAdded ) {
    section.optional = optionalSection;
    section.price -= optionalSection.price;
  }
}

/**
 * Removes sections that have only optional data or no data to be shown under the non-optional sections
 * @param {ISectionPrice} section the section to be sanitized
 * @returns {ISectionPrice} the section after it is sanitized
 */
export const sanitizeSummarySection = ( section:ISectionPrice ) => {
  section.variables = section.variables.map( ( v ) => {
    if( !v.isMarkedOptional ) {
      return {...v,values:v.values.filter( ( val ) => isValidAssignedValue( val ) && !v.isMarkedOptional )}
    } else{
      return v
    }
  } ).filter( ( v )=> v && !v?.isMarkedOptional && v?.values.length && !isSubmodelCountVariable( v ) );
  section.sections = section.sections.filter( sanitizeSummarySection );
  if( !( section.sections.length || section.variables.length || section?.optional && section.optional.variables.length > 0 ) ) {
    return null;
  }
  return section;
}

export function getFeatureValue(value:string, variableType: string )
{
  if (variableType === EVariableType.String) {
    return ` (${value})`;
  } else if (variableType === EVariableType.Number) {
    return ` (x${value})`;
  } else {
    return value;
  }
}