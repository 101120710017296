
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { IApplicationSettings, IClaimsData, IConfigurationVariable, IConfigurationVariablePrice, ISummaryVariableProps, IUserSettings } from '../../types';
import { getFormattedPrice, getValueFromVariable, getSummaryVariableName,getSymbol, getFeatureValue } from '../services';
import { EVariableType } from '../data/Constants';
import { isFeatureVisible } from '../services/ClaimsHelper';
import { isValidCurrency } from '../services/Price';

function showPrice( price:number,variable:IConfigurationVariable,valueBundled?:boolean ) {
  if( price === null ) {
    return false;
  }
  if( price >= 0 ) {
    return true;
  }
  return valueBundled || !( variable.variableType === EVariableType.Singleselect || variable.variableType === EVariableType.Multiselect ) && variable.isBundled;
}

const filterOptionalValues = (variable: IConfigurationVariablePrice, showOptional: boolean) => ({
  ...variable,
  values: variable.values.filter((v) => showOptional || !v.isMarkedOptional),
});

const shouldHideVariable = (values: any, tempVar: IConfigurationVariablePrice, showOptional: boolean) => values.length === 0 || (tempVar.isMarkedOptional && !showOptional);

const getVariableName = (tempVar: IConfigurationVariablePrice, userSettings: IUserSettings, isSimpleConfigurationSummary: boolean) =>
  isSimpleConfigurationSummary && tempVar.variableType !== EVariableType.String && tempVar.variableType !== EVariableType.Number
    ? ''
    : getSummaryVariableName(tempVar, userSettings);

const getFeatureNameComponent = (variableName: string, isSimpleConfigurationSummary: boolean) =>
  !isSimpleConfigurationSummary ? (
    <Box className="section">
      <span className="content" color="text.secondary">
        {variableName}
      </span>
    </Box>
  ) : null;

  const getTableCellContent = (valueName: string, variableName: string, variableType: string, isSimpleConfigurationSummary: boolean, showOptional: boolean) => {
    const variableValueClass = `variableName ${showOptional ? 'optionalValue' : ''}`;
    return isSimpleConfigurationSummary ? (
      <>
        <span className="content" color="text.secondary">
          {variableName}
        </span>
        <span className={variableValueClass}>{getFeatureValue(valueName, variableType)}</span>
      </>
    ) : (
      <span className={variableValueClass}>{valueName}</span>
    );
  };
  
  const shouldShowPrice = (claimsData: IClaimsData, ele: any, variable: IConfigurationVariable, isScopeVariable: boolean) =>
    isFeatureVisible(claimsData.featureFlags.ListPrice) && showPrice(ele.price, variable, ele.isBundled) && !isScopeVariable;
  
  const getPriceContent = (currency: any, price: number, applicationSettings: IApplicationSettings) => (
    <>
      <span className={!isValidCurrency(currency?.currencyCode) ? 'invalidCurrency' : 'content'} color="text.secondary">
        {currency?.currencyCode && getSymbol(currency?.countryCode, currency?.currencyCode)}
      </span>
      <span className="content" color="text.secondary">
        {applicationSettings?.currency.currencyCode && getFormattedPrice(price)}
      </span>
    </>
  );

/**
 * Renders the variable name and price on assigned value.
 * @param {ISummaryVariableProps} props the properties for the variable summary component
 * @returns {JSX.Element} the variable summary component
 */
export const VariableSummary = ( { variable, isScopeVariable,showOptional=false,isSimpleConfigurationSummary }: ISummaryVariableProps ) => {
  const userSettings = useSelector( ( state: AppState )=> state.userSettings )
  const claimsData = useSelector( ( state: AppState )=> state.claimsData )
  const applicationSettings = useSelector( ( state: AppState )=> state.applicationSettings )

  if( !userSettings ) {
    return null;
  }
  //removing optional values when show optional is disabled
  const tempVar = filterOptionalValues(variable, showOptional);
  const values = getValueFromVariable( tempVar, userSettings );
  const currency = applicationSettings?.currency;
  //getting price only for non scope variables
  if( shouldHideVariable(values, tempVar, showOptional) ) {
    return null;
  }

  //getting the name as expected to be displayed in the summary panel
  const variableName = getVariableName(tempVar, userSettings, isSimpleConfigurationSummary);
  const featureNameComponent = getFeatureNameComponent(variableName, isSimpleConfigurationSummary);

  return <Box className="variableSummary root">
  {featureNameComponent}
  <Table size="small" aria-label="Variable Summary Table">
    <TableBody>
      {values.map((ele) => (
        <TableRow key={ele?.name || '' + ele.price}>
          <TableCell component="th" scope="row">
            {getTableCellContent(ele.name, variableName, variable.variableType, isSimpleConfigurationSummary, showOptional)}
          </TableCell>
          {shouldShowPrice(claimsData, ele, variable, isScopeVariable) && (
            <TableCell className="tableCell" align="right">
              {getPriceContent(currency, ele.price, applicationSettings)}
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  </Table>
</Box>
}