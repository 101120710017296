import { AxiosError } from 'axios';
import { getPackagePath, handleError } from '../services';
import { IConfigureRequest } from '../../types';
import { EActionType } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';


export const ConfigurationApi = {
  getViewList : ( action: { configurationId: string, token: string} ) =>{
    return AxiosClient
      .post( '/configurator/v1/configuration/views/read',
        { configurationId: action.configurationId }, getHeaders( action.token )
      )
      .then( ( response )=> {
        return {
          data: response.data
        } 
      } )
      .catch( ( err: {response:{data:{errorMessage:string},status:number},message:string,code:number} ) => {
        return handleError( err, 'noViews' );
      } )
  },

  loadConfiguration: ( action: { configurationId: string, languageCode: string, token: string, viewId: string } ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/read',
        { configurationId: action.configurationId, viewId: action.viewId },getHeaders( action.token, action.languageCode )
      )
      .then( ( response ) => ( {
        data: response.data,
        access: response.headers.access
      } ) ).catch( ( err: AxiosError ) => {
        // To Dispatch Invalid View action on Failure response to Configure API
        return handleError( err, 'configure' );
      } ),

  configure: ( action: { request: IConfigureRequest, token: string, language: string } ) => {
    return AxiosClient
      .post(
        `/configurator/v1/configuration/configure?packagePath=${getPackagePath( action.request.configureRequest?.line.productId )}`,
        action.request, getHeaders( action.token, action.language )
      )
      .then( ( response ) => ( {
        configuration: response.data
      } ) ).catch( ( err: AxiosError ) => {
        // To Dispatch Invalid View action on Failure response to Configure API
        return handleError( err, 'configure' );
      } )
  },

  getPMLanguages: ( action: { configurationId: string, token: string } ) => {
    return AxiosClient
      .get(
        `/product/v1/languages?configurationId=${action.configurationId}`,getHeaders( action.token )
      ).then( ( response ) => {
        return {
          pmLanguages: response.data
        }
      } ).catch( () => {
        //If PM languages failed, default or fall back language would be used
        return null
      } )
  },

  clearConfiguration: ( action: { configurationId: string, token: string, viewId: string } ) => {
    return AxiosClient
      .post( '/configurator/v1/configuration/clear',
        {
          configurationId: action.configurationId,
          viewID: action.viewId
        },getHeaders( action.token )
      ).then( ( response ) => {
        return {
          configuration: response.data,
          apiType: EActionType.ClearAll
        }
      } ).catch( ( err ) => {
        return handleError( err, '' );
      } )
  },

  createConfiguration: ( action: { token: string, request: IConfigureRequest } ) => {
    return AxiosClient
      .post(
        '/punchout/v1/configuration/create',
        action.request,getHeaders( action.token )
      ).then( ( response ) => {
        return {
          configurationId: response.data
        }
      } ).catch( ( err ) => {
        return handleError( err, 'create-configuration', action.request );
      } )
  },

  exportConfigurationBomExcel: ( action: { configurationId: string | null, token: string, isMultipleSheet: boolean } ) => {
    return AxiosClient
      .post( '/report/configuration/bom/excel',
        { configurationId: action.configurationId, isMultipleSheet: action.isMultipleSheet },
        {...getHeaders( action.token),...{responseType: 'arraybuffer'}}
      ).then( ( response ) => {
        return response;
      } ).catch( ( err ) => {
        return handleError( err, '', action?.request );
      } )
  },

  getClaims: ( action: { token: string } ) =>{
    return AxiosClient
      .get( '/configurator/v1/claims/read?applicationName=cflm', getHeaders( action.token)
      ).then( ( response ) => {
        return {
          claims: response.data
        }
      } ).catch( ( err ) => {
        return handleError( err, 'claims' );
      } )
  },
  
  setOptionalItems: ( action: { token:string,configurationId:string,optionalItems:string[]|null } ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/optional/set',
        {configurationId: action.configurationId, optionalItems: action.optionalItems} ,getHeaders( action.token)      )
      .then( ( response ) => {
        return response;
      } ).catch( ( err ) => {
        return handleError( err, '' );
      } ),

};
