import React, { useEffect, useState } from 'react';
import { AppDispatch, AppState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, Select, MenuItem, Grid, Card, CardContent, Tooltip, Pagination, Stack } from '@mui/material';
import { useAuth } from 'oidc-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EConfigurationType, EUrlParams, ETabValue, RecordsPerPage, EFilterRootOptions } from '../data/Constants';
import { IHierarchy, IMyConfiguration, MyConfigurationsProps } from '../../types';
import { getChannelHierarchy, getMyConfigurations, updateMyConfigPageDetails } from '../store/states/ProductSettingsSlice';
import { changeLandingTab } from '../store/states/ConfigurationSlice';
import { SimpleTreeView } from '@mui/x-tree-view';

const handleChangeForRPP = (event: React.ChangeEvent<unknown>, paginationProps) => {
  const { token, configuration, selectedFilter, setMyConfigRecordsPerPage, dispatch } = paginationProps;
  if (event.target.name === 'recordsPerPage') {
    const records = event.target.value;
    setMyConfigRecordsPerPage(records)
    dispatch(getMyConfigurations({ token: token, page: 1, limit: records, productSearch: configuration.productSearchValue, applicationIds: selectedFilter.applicationIds, channelCodes: selectedFilter.channelCodes }))
  }
}

const handlePaginationForConfig = (configPaginationProps) => {
  const { token, value, configuration, selectedFilter, myConfigRecordsPerPage, setMyConfigPage, setMyConfigs, productSettings, dispatch } = configPaginationProps;
debugger;
  if (!productSettings.myConfigurations[value]) {
    dispatch(getMyConfigurations({ token: token, page: value, limit: myConfigRecordsPerPage, productSearch: configuration.productSearchValue, applicationIds: selectedFilter.applicationIds, channelCodes: selectedFilter.channelCodes }));
    setMyConfigPage(value)
    dispatch(updateMyConfigPageDetails({ page: value, limit: myConfigRecordsPerPage, totalRecords: productSettings.myConfigPageDetails.totalRecords, applicationIds: selectedFilter.applicationIds, channelCodes: selectedFilter.channelCodes }))
  } else {
    setMyConfigPage(value)
    dispatch(updateMyConfigPageDetails({ page: value, limit: myConfigRecordsPerPage, totalRecords: productSettings.myConfigPageDetails.totalRecords, applicationIds: selectedFilter.applicationIds, channelCodes: selectedFilter.channelCodes }))
    setMyConfigs(productSettings.myConfigurations[value])
  }
}

const setProdAndPageConfigOnLoad = (pageDetailsPropsOfConfig) => {
  const { page, limit, totalRecords, setMyConfigPage, setMyConfigRecordsPerPage, setTotalMyConfigRecords, setMyConfigs, setProdChannel, productSettings } = pageDetailsPropsOfConfig
  setMyConfigPage(page);
  setMyConfigRecordsPerPage(limit);
  setTotalMyConfigRecords(Math.ceil(totalRecords / limit) || 1);
  setMyConfigs(productSettings.myConfigurations[page] || []);
  setProdChannel(productSettings.channelHierarchy);
}

function formatProductDesc(productDescription: string) {
  const charLength = 50;
  if (productDescription.length > charLength) {
    return productDescription.substring(0, charLength) + '...';
  } else {
    return productDescription;
  }
}

function formatCountryName(CountryName: string, isDesktop: boolean) {
  if (CountryName.length > 18 && !isDesktop) {
    return CountryName.substring(0, 17) + '..';
  } else if (CountryName.length > 25) {
    return CountryName.substring(0, 23) + '..';
  }
  return CountryName;
}

const formatDateString = (dateValue: string) => {
  const date = new Date(dateValue);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const year = date.getFullYear();
  const hour = ('0' + date.getHours()).slice(-2);
  const min = ('0' + date.getMinutes()).slice(-2);
  const seg = ('0' + date.getSeconds()).slice(-2);
  return month + '-' + day + '-' + year + ' ' + hour + ':' + min + ':' + seg;
}

const addStateValues = (state: number) => {
  switch (state) {
    case 0:
      return EConfigurationType.Active;
    case 1:
      return EConfigurationType.ReadOnly;
    case 2:
      return EConfigurationType.Temp;
    default:
      return '';
  }
}

export const MyConfigurations = (myConfigurationsProps: MyConfigurationsProps) => {
  const { isDesktop, renderProductHierarchy } = myConfigurationsProps;

  const auth = useAuth();
  const token = auth.userData?.access_token || '';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  //selectors
  const productSettings = useSelector((state: AppState) => state.productSettings);
  const configuration = useSelector((state: AppState) => state.configuration)

  const [prodChannel, setProdChannel] = useState<IHierarchy[]>([]);
  const [myConfigs, setMyConfigs] = useState([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedChannel, setSelectedChannel] = React.useState<string[]>([]);
  const [myConfigRecordsPerPage, setMyConfigRecordsPerPage] = useState<number>(RecordsPerPage[0]); //Default records size is 15
  const [totalMyConfigRecords, setTotalMyConfigRecords] = useState<number>(1); //Default records size is 1
  const [myConfigPage, setMyConfigPage] = useState<number>(1);
  const [isConfigTabLoading, setIsConfigTabLoading] = useState({ configurations: true, channels: true });
  const [selectedFilter, setSelectedFilter] = useState<{ applicationIds: string[], channelCodes: string[], applicationNames: string[], channelNames: string[] }>({ applicationIds: [], channelCodes: [], applicationNames: [], channelNames: [] });
  const [expanded, setExpanded] = useState<string[]>([EFilterRootOptions.Channel]);
  const treeRootNodes = [EFilterRootOptions.Channel, EFilterRootOptions.Application];
  const showClerfilter: boolean = true;

  const handleChange = (_event: React.ChangeEvent<unknown>, value?: number) => {
    handlePaginationForConfig({ token, value, configuration, selectedFilter, myConfigRecordsPerPage, setMyConfigPage, setMyConfigs, productSettings, dispatch })
  }

  useEffect(() => { //On first load of the component
    if (Object.keys(productSettings.myConfigurations).length === 0) {
      dispatch(getMyConfigurations({ token: token, page: myConfigPage, limit: myConfigRecordsPerPage, productSearch: '', channel: selectedChannel })).finally(() => {
        setIsConfigTabLoading(prevValue => ({ ...prevValue, configurations: false }))
      });
      dispatch(getChannelHierarchy({ token: token })).finally(() => {
        setIsConfigTabLoading(prevValue => ({ ...prevValue, channels: false }))
      });
    }
    setSelectedChannel(productSettings.myConfigPageDetails?.channel ? productSettings.myConfigPageDetails?.channel : [])
    if (Object.keys(productSettings.myConfigurations).length !== 0) {
      setIsConfigTabLoading(prevVal => ({ ...prevVal, configurations: false, channels: false }))
    }
  }, [])

  useEffect(() => {
    const { page, limit, totalRecords } = productSettings.myConfigPageDetails;
    setProdAndPageConfigOnLoad({ page, limit, totalRecords, setMyConfigPage, setMyConfigRecordsPerPage, setTotalMyConfigRecords, setMyConfigs, setProdChannel, productSettings });
  }, [JSON.stringify(productSettings.myConfigurations), JSON.stringify(productSettings.channelHierarchy)])

  return <Box className="product-portfolio-container">
    <Box className="hierarchy-container" style={{ marginTop: '25px' }}>
      <SimpleTreeView
        slots={{
          expandIcon: ChevronRightIcon,
          collapseIcon: ExpandMoreIcon
        }}
        onSelectedItemsChange={(event, nodeId) => {
          const index = expanded.indexOf(nodeId);
          const isRootNode = treeRootNodes.includes(nodeId);
          const copyExpanded = [...expanded];
          if (isRootNode) {
            if (index === -1) {
              copyExpanded.push(nodeId);
            } else {
              copyExpanded.splice(index, 1);
            }
            setExpanded(copyExpanded);
          }

        }}
        defaultExpandedItems={expanded}
        className="filter-treeview"
      >
        {isConfigTabLoading.channels ? <span className="noProduct-label">{t('landingPage.loading') + '...'}</span> :
          !isConfigTabLoading.channels && renderProductHierarchy(prodChannel, { selected, selectedChannel, configuration, setSelected, setSelectedChannel, token, productSettings, selectedFilter, setSelectedFilter }, t, ETabValue.MyConfiguration, dispatch, expanded, showClerfilter)}
      </SimpleTreeView>
    </Box>

    {!isConfigTabLoading.configurations && myConfigs.length === 0 ? <span className="noConfigurations-label">{t('landingPage.noConfigurationsFound')}</span> :
      <div className="myConfigurations-Tab" >
        <Box className="recordsChange-conatiner">
          <FormControl size="small" className="" style={{ width: '4.5rem', marginLeft: '5px' }}>
            <Select
              id="recordsPerPage"
              name="recordsPerPage"
              value={myConfigRecordsPerPage}
              onChange={(e) => handleChangeForRPP(e, { token, configuration, selectedFilter, setMyConfigRecordsPerPage, dispatch })}
              className="recordsPerPage"
            >
              {RecordsPerPage.map(range => <MenuItem key={range} value={range}>{range}</MenuItem>)}
            </Select>
          </FormControl>
          <label>{t('labels.records')} :</label>
        </Box>
        <Box className="myConfigurations-list-Tab">
          <Grid container spacing={1}>
            {myConfigs.map((config: IMyConfiguration, index: number) => {
              return <Grid key={config.configurationId} item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card sx={{ border: '1px solid grey' }} className="minh-100" id={'mc-' + index} data-testid={'mcCard' + index}>
                  <CardContent>
                    <div className="cardLayoutMainDiv">
                      <div>
                        <img src="public\png\SamplePMImage.png" style={{ height: '80px', width: '80px', marginLeft: '5px', marginTop: '10px' }} />
                      </div>

                      <div style={{ display: 'block', marginLeft: '10px', width: '100%' }}>
                        <div className="cardLayoutChildProdDiv">
                          <Tooltip placement="bottom-start" title={config.productDescription}>
                            <span style={{ color: '#1474A4', fontWeight: 'bold' }}>{formatProductDesc(config.productDescription)} ({config.productId})</span>
                          </Tooltip>
                        </div>

                        <div>
                          <span className="configurationName" ><b>{t('labels.name')}: </b>
                            <span className="configName" onClick={() => {
                              dispatch(changeLandingTab({ value: true, tabValue: ETabValue.Product }))
                              navigate('/?' + EUrlParams.ConfigurationId + '=' + config.configurationId, { replace: true });
                            }}
                            >{config.name}</span>
                          </span>
                        </div>

                        <div style={{ display: 'flex' }}>
                          <div className="cardLayoutChildDiv w-40">
                            <span><b>{t('labels.Id')}:</b> {config.configurationId.substring(0, 18)}</span>
                            <span><b>{t('labels.country')}</b>: {formatCountryName(config.countryName, isDesktop)} ({config.countryCode})</span>
                            <span><b>{t('labels.state')}:</b> {addStateValues(config.state)}</span>
                          </div>

                          <div className="cardLayoutChildDiv w-60">
                            <span><b>{t('labels.createdOn')}</b>: {formatDateString(config.createdOn) + ' UTC'}</span>
                            <span><b>{t('labels.modifiedOn')}</b>: {formatDateString(config.modifiedOn) + ' UTC'}</span>
                            <span><b>{t('labels.application')}</b>: {(config.createdFromName)} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>

                </Card>
              </Grid>
            })}
          </Grid>
        </Box>

        <Stack spacing={1} className="myConfig-pagination">
          <Pagination color="primary" count={totalMyConfigRecords} page={myConfigPage} onChange={handleChange} />
        </Stack>

      </div>
    }

  </Box>
}
